<template>
    <div class="h-100-vh">
        <div class="card card-ecart  p-0 p-md-4 p-lg-5" v-if=" !loading.data">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-lg-4 text-center mb-4 mb-lg-0">
                        <img v-image="reviewInformation.product.imageUrl" class="img-fluid border" alt="">
                    </div>
                    <div class="col-12 col-lg-8 d-flex flex-column justify-content-between ">
                        <div>
                            <p class="f-24 text-success" v-html="fnGetStars(reviewInformation.review)"></p>
                            <p class="f-30" v-text=' `" ` + reviewInformation.comment + ` "` '>
                            </p>
                            <hr>
                            <p v-text="reviewInformation.product.name" v-if="reviewInformation.product.id"></p>
                            <p class="text-warning" v-else>
                                <strong>
                                    <i class="fa fa-info-circle mr-1"></i> <span v-text="$t('messages.productNoExist')"></span>
                                </strong>
                            </p>
                        </div>
                        <div v-if="reviewInformation.product.id">
                            <p class="text-right">
                                <router-link :to=" '/dashboard/products/' + reviewInformation.product.id "
                                    v-text=" $t('dashboard.product.title') "></router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <no-data v-else :isLoading="loading.data" :dataMessage="$t('dashboard.stores.integration.message')"
            :btnMessage=" $t('general.newStore') ">
        </no-data>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex'
    export default {
        data() {},
        computed: {
            ...mapState('EcartReview', ['reviewInformation', 'loading']),
        },
        methods: {
            ...mapActions('EcartReview', ['fnApiGetReview']),
        },
        mounted() {
            this.fnApiGetReview(this.$route.params.id);
        }
    }
</script>

<style>

</style>